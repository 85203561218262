<template>

<div class="modal" style="width: auto" v-bind:class="{ 'is-active': isShowDialog }">
  <div class="modal-background"></div>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Authorize a User</p>
      <button class="delete" aria-label="close" @click="cancelForm"></button>
    </header>
    <form action="" class="box" @submit.prevent="submitForm">
    <section class="modal-card-body">
            <b-field label="Email" label-for="userRecord.email">
                <b-input name="email" id="userRecord.email" type="email" icon-pack="fas" icon="envelope" v-model="userRecord.email" validation-message="Please enter a vaild email address"></b-input>
            </b-field>
            <b-field label="Role" label-for="userRecord.role">
                <b-select name="role" id="role" v-model="userRecord.role" placeholder="User Role">
                    <option value="ACCOUNTANT">Accountant</option>
                    <option value="MANAGER">Manager</option>
                    <option value="OWNER">Owner</option>
                    <option value="PLATFORM_FUEL_DATA">Fuel Inventory API</option>
                    <option value="STAFF">Staff</option>                    
                </b-select>
            </b-field>
	</section>

    <footer class="modal-card-foot">
      <button class="button is-success" type="button" @click="authorizeUser" :disabled="isDisabled">Authorize</button>
      <button class="button" type="button" @click="cancelForm">Cancel</button>
      <span class='is-danger'></span>
      
    </footer>
   </form>

  </div>
</div>

</template>


<script>

// import UserStore from "../../../../store/UserStore"
// import axios from 'axios'

export default {
    props: {
         'canCancel': String, 
         'isShowDialog': Boolean,
         'storeId': Number,
         'action': String,
         'userRecord': Object
    },

    data() {
        return {
            'email': '',
            'role': ''
        }
    },

    methods: {
        cancelForm() {
            this.$emit('hide-dialog')
            this.userRecord.email = ""
            this.userRecord.role = ""
        },

        authorizeUser() {
            this.$emit('authorize-user')
        },

        // submitForm() {
        //     this.$emit('hide-dialog')

        //     var url = process.env.VUE_APP_API_ROOT_URL + 'users/authorize'
        //     axios.post(url, {
        //         email: this.email,
        //         role: this.role,
        //         storeId: this.storeId,
        //         userId: this.userId,
        //         action: this.action
        //     },
        //     {
        //         headers: {
        //             'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
        //             'Refresh-Token': UserStore.getters.getRefreshToken
        //         },
        //     })
        //     .then( () => {
        //         this.$emit('refresh-data')
        //         this.email = ''
        //         this.userId = ''
        //         this.role = ''
        //     })
        //     .catch( (err) => {
        //         if (process.env.VUE_APP_DEBUG == 'true') console.log(err)
        //     })
        // },

    },

    computed: {
        isDisabled() {
            if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.userRecord.email) && this.userRecord.role ) {
                return false
            } else {
                return true
            }
            
        },
    },    

}
</script>